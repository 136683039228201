.CurrentOffer {
  display: flex;
  align-items: flex-end;
  gap: 20px;

  .currencyContainer {
    position: relative;

    .error,
    .warning {
      //  display: none;
      position: absolute;
      font-family: Gilroy-Regular;
      font-size: 14px;
    }

    .error {
      color: rgb(255, 45, 85);
    }

    .warning {
      color: #ffbf00;
    }
  }

  .dropdownTitle {
    margin-bottom: 5px;
    font-family: 'Gilroy-Bold';
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #242833;
    white-space: nowrap;

    .note {
      font-family: 'Gilroy-Regular';
      font-size: 16px;
      color: #c1c5d6;
    }

    &.short {
      width: 20px;
    }
  }
}
