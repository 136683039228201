@use '../../../../../../variables' as var;

@keyframes shake {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(60deg);
  }
  75% {
    transform: rotate(70deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes shakeReverse {
  0% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(70deg);
  }
  50% {
    transform: rotate(60deg);
  }
  75% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.5);
  }
}

@keyframes scaleAnimationReverse {
  0% {
    transform: scale(1.5);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.MessageInput {
  display: flex;
  gap: 21px;
  padding-top: 46px;
  padding-right: 30px;
  position: relative;
  .scaleTransition {
    transition: all 0.2s ease-in-out;
  }
  .mouseOverScale {
    animation: scaleAnimation 0.2s ease-in-out;
    transform: scale(1.5);
  }
  .mouseLeaveScale {
    animation: scaleAnimationReverse 0.2s ease-in-out;
    transform: scale(1);
  }

  .mouseOverUsers {
    animation: shake 0.2s ease-in-out;
    transform: rotate(90deg);
  }
  .mouseLeaveUsers {
    animation: shakeReverse 0.2s ease-in-out;
    transform: rotate(0deg);
  }
  .sentWrapper {
    position: relative;
    height: 60px;
    width: 60px;
    flex-shrink: 0;
    cursor: pointer;
    background: #c1c5d6;
    overflow: hidden;
    border-radius: 20px;
    &:hover {
      .graybackgroundExpanded {
        transform: scale(1);
        border-radius: 10px;
      }
      .sent {
        &::after {
          left: 15px;
        }
      }
    }
  }
  .graybackgroundExpanded,
  .greenbackgroundExpanded {
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .graybackgroundExpanded {
    background: var.$middle-gray-color;
  }
  .greenbackgroundExpanded {
    background: var.$light-green;
  }
  .showGreenBg {
    transform: scale(1);
    border-radius: 10px;
  }

  .sent {
    z-index: 1;
    position: relative;
    width: 60px;
    height: 60px;
    opacity: 0.92;
    border-radius: 20px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 27px;
      transition: all 0.3s ease-in-out;
      height: 27px;
      background: url('../../../../../../assets/images/dashboard/discover/comments/sent.svg');
    }
    &.moveArrow {
      &::after {
        left: 70px !important;
        transform: scale(1.5);
      }
    }

    &.active {
      background: #00e4ac;
      cursor: pointer;
    }
    &.moveArrowLeft {
      &::after {
        transform: scale(1);
      }
    }
  }

  // .sendButton {
  //   width: 60px;
  //   height: 60px;
  //   font-size: 0;
  //   background-image: url(../../../../../../assets/images/chat/send.svg);
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-color: rgba(0, 228, 172, 0.92);
  //   border-radius: 20px;
  //   border: none;
  //   backdrop-filter: blur(13.8632px);

  //   &:disabled {
  //     background-color: rgba(193, 197, 214, 0.92);
  //   }
  // }
}
