.Header {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  .titleContainer {
    display: flex;
    align-items: center;

    .headerDate {
      margin-left: 20px;
      font-family: Gilroy-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #242833;
    }
  }

  .row {
    justify-content: space-between;
    flex-grow: 1;
    display: flex;
    align-items: center;

    & div {
      display: flex;
    }

    .headline {
      margin: 0;
      font-family: 'Gilroy-Bold';
      font-size: 28px;
      line-height: 30px;
      white-space: nowrap;
    }

    .status {
      margin-left: 15px;
      display: flex;
      align-items: center;
      gap: 11px;

      .statusText {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        padding-left: 15px;
        padding-right: 15px;
        font-family: 'Gilroy-Bold';
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        color: white;
        background: #00e4ac;
        border-radius: 16px;
      }

      .statusDeclined {
        background-color: #ff2d55;
      }

      .changeRequest {
        background-color: #ffbf00;
      }
    }

    .date {
      margin-left: 15px;
    }

    .statusDate {
      font-family: 'Gilroy-SemiBold';
      font-size: 16px;
      line-height: 24px;
      color: #ffbf00;
    }
  }
}

@media (max-width: 1024px) {
  .Header {
    .titleContainer {
      flex-wrap: wrap;
      row-gap: 5px;
      column-gap: 15px;

      .headerDate {
        margin-left: 0;
      }
    }

    .row {
      .status {
        margin-left: 0;
      }
    }
  }
}
